export default {
  profile: {
    navigation: {
      venues: {
        label: "Your venues",
        icon: "🏢",
      },
      account: {
        label: "Account",
        icon: "👤",
      },
      levels: {
        label: "Game levels",
        icon: "🎮",
      },
    },
  },
  reservations: {
    navigation: {
      single: {
        label: "Single",
        icon: "🎾",
      },
      recurring: {
        label: "Recurring",
        icon: "🔄",
      },
      single_class: {
        label: "Single classes",
        icon: "👥",
      },
      recurring_class: {
        label: "Recurring classes",
        icon: "👥",
      },
    },
    filters: {
      upcoming: "Upcoming",
      past: "Past",
      showCancelled: "Show cancelled",
    },
    empty: {
      title: "No reservations",
      upcoming: "You don't have any upcoming reservations.",
      past: "You don't have any past reservations.",
    },
    sections: {
      single: {
        title: "Single reservations",
        empty: "No single reservations",
      },
      recurring: {
        title: "Recurring reservations",
        empty: "No recurring reservations",
      },
      single_class: {
        title: "Single classes",
        empty: "No single classes",
      },
      recurring_class: {
        title: "Recurring classes",
        empty: "No recurring classes",
      },
    },
    recurring: {
      until: "Until",
      paid: "paid",
      unpaid: "unpaid",
      details: {
        info: {
          term: "Term",
          endDate: "End date",
          reservations: "Reservations",
          totalAmount: "Total amount",
        },
        sections: {
          upcoming: "Upcoming reservations",
          past: "Past reservations",
        },
        selectAll: "Select all unpaid",
        trainer: "Trainer",
        status: {
          cancelled: "Cancelled",
          paid: "Paid",
          unpaid: "Unpaid",
        },
      },
      payment: {
        button: "Pay selected",
        modal: {
          title: {
            select: "Select payment method",
            required: "Payment required",
          },
          info: {
            title: "Reservations require payment",
            message:
              "To confirm reservations, please complete the payment within 30 minutes. After this time, reservations will be automatically cancelled.",
          },
          buttons: {
            cancel: "Cancel",
            continue: "Continue",
            payment: "Proceed to payment",
          },
        },
      },
      legal: {
        text: "By placing an order, the User declares that they have read and accept the terms and conditions and privacy policy of the selected sports facility, as well as",
        terms: "Playmore terms of service",
        fee: "(formerly Reservise.com). According to the price list, a service fee of 1.98 PLN gross will be added to each transaction for electronic payment processing costs.",
      },
    },
    details: {
      loading: "Loading reservation details...",
      status: {
        active: "Reservation active",
        cancelled: "Reservation cancelled",
        cancelled_not_on_time: "Reservation cancelled not on time",
      },
      payment: {
        pending: {
          title: "Payment pending",
          message:
            "Remember to complete the payment before the reservation starts.",
          serviceFee:
            "A service fee of 1.98 PLN will be added to online payment.",
          button: "Pay {price} PLN",
        },
        completed: {
          title: "Reservation paid",
          message: "Payment has been completed. You can use the reservation.",
        },
      },
      info: {
        title: "Reservation details",
        dateTime: "Date and time",
        court: "Court",
        prices: {
          base: "Base price",
          discountCards: "Discount cards ({count})",
          serviceFee: "Service fee",
          total: "Total price",
        },
      },
      cancellation: {
        title: "Cancel reservation",
        free: {
          message:
            "You can cancel this reservation without additional charges according to the venue's policy. Free cancellation is available up to {hours} hours before the start time.",
          button: "Cancel reservation",
        },
        paid: {
          message:
            "Cancelling the reservation less than {hours} hours before the start time may result in additional charges according to the venue's policy.",
          button: "Cancel reservation (not on time)",
        },
        modal: {
          title: {
            free: "Cancel reservation",
            paid: "Cancel reservation not on time",
          },
          message: {
            free: "Are you sure you want to cancel this reservation?",
            paid: "Cancelling the reservation after the deadline may result in additional charges. Do you want to continue?",
          },
          buttons: {
            cancel: "Cancel",
            confirm: "Confirm",
          },
        },
      },
      buttons: {
        backToList: "Back to reservations",
        bookAgain: "Book again",
      },
      payment_modal: {
        title: {
          select: "Select payment method",
          required: "Payment required",
        },
        info: {
          title: "Reservation requires payment",
          message:
            "To confirm the reservation, please complete the payment within 30 minutes. After this time, the reservation will be automatically cancelled.",
        },
        buttons: {
          cancel: "Cancel",
          continue: "Continue",
          payment: "Proceed to payment",
        },
      },
      legal: {
        payment: {
          text: "Payment processing is provided by Montonio Finance OÜ (",
          link: "legal information",
          suffix:
            "). All payment-related complaints should be directed to the payment operator.",
        },
      },
    },
    tile: {
      cancellation: {
        not_on_time: "Cancelled not on time",
      },
      separator: "•",
    },
    loading: "Loading reservations...",
  },
  carnets: {
    title: "Your Passes",
    loading: "Loading passes...",
    empty: {
      title: "No passes",
      message:
        "You don't have any passes yet. Check our offer and buy your first pass!",
    },
    card: {
      status: {
        available: "Available",
        unavailable: "Unavailable",
      },
      dates: {
        added: "Date added",
        expiration: "Expiration date",
        noDate: "No date",
      },
      value: {
        remaining: "Remaining value",
        total: "Total value",
      },
      price: "Price",
      usage: {
        history: "Usage history",
        empty: "No usage history",
      },
    },
  },
};
