export default {
  title: "Статус оплати",
  messages: {
    PENDING: {
      title: "Очікуємо вашу оплату",
      description: "Завершіть процес оплати, щоб підтвердити бронювання",
    },
    PAID: {
      title: "Дякуємо за оплату!",
      description: "Ваше бронювання підтверджено",
    },
    VOIDED: {
      title: "Оплату відхилено",
      description: "На жаль, оплату не вдалося обробити. Спробуйте ще раз",
    },
    PARTIALLY_REFUNDED: {
      title: "Оплату частково повернуто",
      description: "Частину суми повернуто на ваш рахунок",
    },
    REFUNDED: {
      title: "Оплату повернуто",
      description: "Повну суму повернуто на ваш рахунок",
    },
    ABANDONED: {
      title: "Оплату не завершено",
      description: "Перевищено ліміт часу на оплату",
    },
  },
  refreshButton: "Оновити статус",
  errors: {
    fetch: "Не вдалося отримати статус платежу",
    invalidId: "Недійсний ідентифікатор платежу",
    notFound: "Платіж з вказаним ідентифікатором не знайдено",
    unauthorized: "Немає дозволу на перегляд цього платежу",
    serverError: "Виникла помилка сервера під час отримання статусу платежу",
  },
  noPaymentId: "Відсутній ID платежу",
};
