export default {
  title: "Абонементи для {venue}",
  loading: "Завантаження абонементів...",
  errors: {
    loading: "Не вдалося завантажити абонементи. Спробуйте ще раз пізніше.",
  },
  card: {
    duration: "Тривалість",
    days: "днів",
    type: {
      label: "Тип",
      amount: "Грошовий",
      entries: "Кількісний",
    },
    value: {
      label: "Вартість",
      amount: "зл",
      entries: "відвідувань",
    },
    price: {
      label: "Ціна",
      currency: "зл",
    },
    availableHours: {
      title: "Доступні години",
    },
    actions: {
      buy: "Купити абонемент",
    },
  },
};
