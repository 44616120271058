// Importy komponentów
const Error500 = () => import("@/views/error-pages/Error500.vue");
const Error404 = () => import("@/views/error-pages/Error404.vue");

export const errorRoutes = [
  {
    path: "/500",
    name: "Error500",
    component: Error500,
    meta: {
      requiresAuth: false,
      title: "Błąd serwera | Playmore — Player",
      error: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error404",
    component: Error404,
    meta: {
      requiresAuth: false,
      title: "Strona nie znaleziona | Playmore — Player",
      error: true,
    },
  },
];
