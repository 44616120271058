// Importy komponentów
const Reservations = () => import("@/views/reservations/reservations.vue");
const ReservationCourts = () =>
  import("@/views/reservations/ReservationCourts.vue");
const ReservationClasses = () =>
  import("@/views/reservations/ReservationClasses.vue");
const ReservationTrainers = () =>
  import("@/views/reservations/ReservationTrainers.vue");
const ReservationCarnets = () =>
  import("@/views/reservations/ReservationCarnets.vue");
const ReservationDetails = () =>
  import("@/views/users/reservations/ReservationDetails.vue");
const RecurringReservationDetails = () =>
  import("@/views/users/reservations/RecurringReservationDetails.vue");

export const reservationRoutes = [
  {
    path: "/reservation/:venueId",
    component: Reservations,
    meta: { requiresAuth: false, title: "Rezerwacja | Playmore — Player" },
    children: [
      {
        path: "",
        redirect: (to) => ({
          name: "ReservationCourts",
          params: { venueId: to.params.venueId },
        }),
      },
      {
        path: "courts",
        name: "ReservationCourts",
        component: ReservationCourts,
      },
      {
        path: "classes",
        name: "ReservationClasses",
        component: ReservationClasses,
      },
      {
        path: "trainers",
        name: "ReservationTrainers",
        component: ReservationTrainers,
      },
      {
        path: "carnets",
        name: "ReservationCarnets",
        component: ReservationCarnets,
      },
    ],
  },
  {
    path: "/reservation/:venueId/details/:reservationId",
    name: "ReservationDetails",
    component: ReservationDetails,
    meta: {
      requiresAuth: true,
      title: "Szczegóły rezerwacji | Playmore — Player",
    },
  },
  {
    path: "/my-reservations/recurring/:parentId",
    name: "RecurringReservationDetails",
    component: RecurringReservationDetails,
    meta: {
      requiresAuth: true,
      title: "Szczegóły rezerwacji stałej | Playmore — Player",
    },
  },
];
