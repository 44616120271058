export default {
  header: {
    title: "{name}, możesz być z siebie dumny!",
    subtitle: "Ty i rakieta tworzycie super duet. Oto Twoje statystyki:",
  },
  general: {
    title: "W skrócie",
    total: "Zarezerwowałeś",
    totalSuffix: "razy",
    active: "W tym aktywnych",
    cancelled: "Anulowanych",
    hoursPlayed: "Spędziłeś na kortach",
    hoursSuffix: "godzin",
  },
  venues: {
    title: "Twoje ulubione miejsca",
    empty: "Nie masz jeszcze żadnych rezerwacji",
    reservations: "rezerwacji",
    hoursPlayed: "h spędzonych na grze",
    details: {
      address: "Znajdziesz nas",
      phone: "Zadzwoń",
      email: "Napisz",
    },
    book: "Zarezerwuj",
  },
  trend: {
    title: "Trend rezerwacji",
    more: "Rezerwujesz częściej niż wcześniej",
    less: "Rezerwujesz rzadziej niż wcześniej",
    same: "Utrzymujesz stały poziom aktywności",
    recent: "Ostatnie 3 miesiące",
    older: "Poprzednie 3 miesiące",
    reservations: "rezerwacji",
  },
  days: {
    title: "Twoje ulubione dni",
    empty: "Nie mamy jeszcze danych o Twoich ulubionych dniach",
    monday: "Poniedziałek",
    tuesday: "Wtorek",
    wednesday: "Środa",
    thursday: "Czwartek",
    friday: "Piątek",
    saturday: "Sobota",
    sunday: "Niedziela",
  },
  hours: {
    title: "Kiedy najczęściej grasz",
    empty: "Nie mamy jeszcze danych o Twoich ulubionych godzinach",
    timeSuffix: "to Twoja ulubiona pora",
  },
  playStyle: {
    title: "Twój styl gry",
    empty: "Nie mamy jeszcze wystarczająco danych o Twoim stylu gry",
    averageTime: {
      title: "Średni czas gry",
      description: "Zazwyczaj grasz po",
    },
    frequency: {
      title: "Częstotliwość gry",
      perWeek: "razy w tygodniu",
      perMonth: "razy w miesiącu",
      regular: "Grasz regularnie",
      irregular: "Grasz nieregularnie",
    },
    duration: {
      title: "Preferowana długość gry",
      minutes: "minut",
      mostCommon: "Najczęściej wybierasz",
    },
    courtType: {
      title: "Preferowany typ kortu",
      squash: "Kort do squasha",
      tennis: "Kort tenisowy",
      mostPlayed: "Najczęściej grasz na",
    },
    gameType: {
      title: "Typ gry",
      singles: "Gry pojedyncze",
      doubles: "Gry podwójne",
      preferred: "Preferujesz",
    },
  },
};
