export default {
  profile: {
    navigation: {
      venues: {
        label: "Twoje obiekty",
        icon: "🏢",
      },
      account: {
        label: "Konto",
        icon: "👤",
      },
      levels: {
        label: "Poziomy gry",
        icon: "🎮",
      },
    },
  },
  reservations: {
    navigation: {
      single: {
        label: "Pojedyncze",
        icon: "🎾",
      },
      recurring: {
        label: "Stałe",
        icon: "🔄",
      },
      single_class: {
        label: "Zajęcia pojedyncze",
        icon: "👥",
      },
      recurring_class: {
        label: "Zajęcia stałe",
        icon: "👥",
      },
    },
    filters: {
      upcoming: "Nadchodzące",
      past: "Rozegrane",
      showCancelled: "Pokaż anulowane",
    },
    empty: {
      title: "Brak rezerwacji",
      upcoming: "Nie masz żadnych nadchodzących rezerwacji.",
      past: "Nie masz żadnych rozegranych rezerwacji.",
    },
    sections: {
      single: {
        title: "Rezerwacje pojedyncze",
        empty: "Brak pojedynczych rezerwacji",
      },
      recurring: {
        title: "Rezerwacje stałe",
        empty: "Brak stałych rezerwacji",
      },
      single_class: {
        title: "Zajęcia pojedyncze",
        empty: "Brak pojedynczych zajęć",
      },
      recurring_class: {
        title: "Zajęcia stałe",
        empty: "Brak stałych zajęć",
      },
    },
    recurring: {
      until: "Do",
      paid: "opłaconych",
      unpaid: "nieopłaconych",
      details: {
        info: {
          term: "Termin",
          endDate: "Data zakończenia",
          reservations: "Rezerwacje",
          totalAmount: "Łączna kwota",
        },
        sections: {
          upcoming: "Nadchodzące rezerwacje",
          past: "Rozegrane rezerwacje",
        },
        selectAll: "Zaznacz wszystkie nieopłacone",
        trainer: "Trener",
        status: {
          cancelled: "Anulowana",
          paid: "Opłacona",
          unpaid: "Nieopłacona",
        },
      },
      payment: {
        button: "Opłać zaznaczone",
        modal: {
          title: {
            select: "Wybierz metodę płatności",
            required: "Płatność wymagana",
          },
          info: {
            title: "Rezerwacje wymagają płatności",
            message:
              "Aby potwierdzić rezerwacje, dokonaj płatności w ciągu 30 minut. Po tym czasie rezerwacje zostaną automatycznie anulowane.",
          },
          buttons: {
            cancel: "Anuluj",
            continue: "Kontynuuj",
            payment: "Przejdź do płatności",
          },
        },
      },
      legal: {
        text: "Składając zamówienie, Użytkownik oświadcza, iż zapoznał się i akceptuje postanowienia regulaminu oraz polityki prywatności wybranego obiektu sportowego, a także",
        terms: "regulamin świadczenia usług Playmore",
        fee: "(dawniej Reservise.com). Zgodnie z cennikiem, do każdej transakcji zostanie doliczona opłata serwisowa w wysokości 1.98 zł brutto tytułem kosztów obsługi płatności elektronicznych.",
      },
    },
    details: {
      loading: "Ładowanie szczegółów rezerwacji...",
      status: {
        active: "Rezerwacja aktywna",
        cancelled: "Rezerwacja anulowana",
        cancelled_not_on_time: "Rezerwacja anulowana nie na czas",
      },
      payment: {
        pending: {
          title: "Płatność oczekująca",
          message:
            "Pamiętaj o dokonaniu płatności przed rozpoczęciem rezerwacji.",
          serviceFee:
            "Do płatności online zostanie doliczona opłata serwisowa w wysokości 1.98 zł.",
          button: "Zapłać {price} zł",
        },
        completed: {
          title: "Rezerwacja opłacona",
          message:
            "Płatność została zrealizowana. Możesz korzystać z rezerwacji.",
        },
      },
      info: {
        title: "Szczegóły rezerwacji",
        dateTime: "Data i godzina",
        court: "Kort",
        prices: {
          base: "Cena podstawowa",
          discountCards: "Karty zniżkowe ({count})",
          serviceFee: "Opłata manipulacyjna",
          total: "Całkowita cena",
        },
      },
      cancellation: {
        title: "Anuluj rezerwację",
        free: {
          message:
            "Możesz anulować tę rezerwację bez dodatkowych opłat zgodnie z regulaminem obiektu. Rezerwację można anulować bezpłatnie do {hours} godzin przed jej rozpoczęciem.",
          button: "Anuluj rezerwację",
        },
        paid: {
          message:
            "Anulowanie rezerwacji w czasie krótszym niż {hours} godziny przed jej rozpoczęciem może wiązać się z dodatkowymi opłatami zgodnie z regulaminem obiektu.",
          button: "Anuluj rezerwację (nie na czas)",
        },
        modal: {
          title: {
            free: "Anulowanie rezerwacji",
            paid: "Anulowanie rezerwacji nie na czas",
          },
          message: {
            free: "Czy na pewno chcesz anulować tę rezerwację?",
            paid: "Anulowanie rezerwacji po czasie może skutkować naliczeniem dodatkowych opłat. Czy chcesz kontynuować?",
          },
          buttons: {
            cancel: "Anuluj",
            confirm: "Potwierdź",
          },
        },
      },
      buttons: {
        backToList: "Wróć do listy rezerwacji",
        bookAgain: "Zarezerwuj ponownie",
      },
      payment_modal: {
        title: {
          select: "Wybierz metodę płatności",
          required: "Płatność wymagana",
        },
        info: {
          title: "Rezerwacja wymaga płatności",
          message:
            "Aby potwierdzić rezerwację, dokonaj płatności w ciągu 30 minut. Po tym czasie rezerwacja zostanie automatycznie anulowana.",
        },
        buttons: {
          cancel: "Anuluj",
          continue: "Kontynuuj",
          payment: "Przejdź do płatności",
        },
      },
      legal: {
        payment: {
          text: "Obsługę płatności realizuje Montonio Finance OÜ (",
          link: "informacje prawne",
          suffix:
            "). Wszelkie reklamacje dotyczące płatności należy kierować bezpośrednio do operatora płatności.",
        },
      },
    },
    tile: {
      cancellation: {
        not_on_time: "Anulowane nie na czas",
      },
      separator: "•",
    },
    loading: "Ładowanie rezerwacji...",
  },
  carnets: {
    title: "Twoje Karnety",
    loading: "Ładowanie karnetów...",
    empty: {
      title: "Brak karnetów",
      message:
        "Nie masz jeszcze żadnych karnetów. Sprawdź naszą ofertę i kup swój pierwszy karnet!",
    },
    card: {
      status: {
        available: "Dostępny",
        unavailable: "Niedostępny",
      },
      dates: {
        added: "Data dodania",
        expiration: "Data wygaśnięcia",
        noDate: "Brak daty",
      },
      value: {
        remaining: "Pozostała wartość",
        total: "Wartość całkowita",
      },
      price: "Cena",
      usage: {
        history: "Historia użycia",
        empty: "Brak historii użycia",
      },
    },
  },
};
