// Importy komponentów
const Overview = () => import("@/views/trainerProfile/Overview.vue");
const WorkingHours = () => import("@/views/trainerProfile/WorkingHours.vue");
const Reservations = () => import("@/views/trainerProfile/Reservations.vue");
const Trainers = () => import("@/views/reservations/ReservationTrainers.vue"); // Poprawiona ścieżka

export const trainerRoutes = [
  {
    path: "/trainer-profile",
    name: "TrainerProfileOverview",
    component: Overview,
    meta: { requiresAuth: true, title: "Profil trenera | Playmore — Player" },
  },
  {
    path: "/trainer-schedule",
    name: "TrainerProfileSchedule",
    component: WorkingHours,
    meta: { requiresAuth: true, title: "Godziny pracy | Playmore — Player" },
  },
  {
    path: "/trainer-reservations",
    name: "TrainerProfileReservations",
    component: Reservations,
    meta: {
      requiresAuth: true,
      title: "Rezerwacje trenera | Playmore — Player",
    },
  },
  {
    path: "/trainers/:venueId",
    name: "Trainers",
    component: Trainers,
    meta: { requiresAuth: false, title: "Trenerzy | Playmore — Player" },
  },
];
