<template>
  <nav class="bg-dynamic-orange">
    <div class="container mx-auto px-4">
      <div class="flex items-center h-16">
        <!-- Logo and main navigation -->
        <div class="flex items-center flex-grow">
          <img
            src="@/assets/logo.png"
            :alt="t('app.layout.header.logo.alt')"
            class="h-8 w-auto"
          />

          <!-- Hamburger menu button -->
          <button
            v-if="isAuthenticated"
            @click="isMenuOpen = !isMenuOpen"
            class="ml-4 sm:hidden p-2 text-off-white hover:text-light-grey"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                v-if="!isMenuOpen"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
              <path
                v-else
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <!-- Desktop navigation -->
          <div
            v-if="isAuthenticated"
            class="hidden sm:flex sm:items-center sm:ml-8 sm:space-x-6"
          >
            <router-link
              to="/your-account"
              class="text-off-white hover:text-light-grey text-body-medium"
              >{{ t("nav.profile") }}</router-link
            >
            <router-link
              to="/trainer-profile"
              class="text-off-white hover:text-light-grey text-body-medium"
              >{{ t("nav.trainerProfile") }}</router-link
            >
            <router-link
              to="/my-carnets"
              class="text-off-white hover:text-light-grey text-body-medium"
              >{{ t("nav.passes") }}</router-link
            >
            <router-link
              to="/my-reservations"
              class="text-off-white hover:text-light-grey text-body-medium"
              >{{ t("nav.reservations") }}</router-link
            >
            <router-link
              to="/statistics"
              class="text-off-white hover:text-light-grey text-body-medium"
              >{{ t("nav.statistics") }}</router-link
            >
          </div>
        </div>

        <!-- Right side: Dark mode toggle, Language switcher, logout button and user avatar -->
        <div class="flex items-center space-x-4">
          <!-- Dark mode toggle -->
          <button
            @click="toggleDarkMode"
            class="text-off-white hover:text-light-grey"
            :title="
              isDark ? 'Przełącz na tryb jasny' : 'Przełącz na tryb ciemny'
            "
          >
            <!-- Sun icon for dark mode -->
            <svg
              v-if="isDark"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <!-- Moon icon for light mode -->
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          </button>

          <!-- Language switcher - zawsze widoczny -->
          <LanguageSwitcher class="text-off-white" />

          <!-- Przyciski tylko dla zalogowanych -->
          <template v-if="isAuthenticated">
            <button
              @click="handleLogout"
              class="text-off-white hover:text-light-grey text-body-medium"
            >
              {{ t("nav.logout") }}
            </button>
            <div
              class="w-8 h-8 rounded-full bg-off-white flex items-center justify-center text-dynamic-orange font-bold"
            >
              {{ userInitials }}
            </div>
          </template>
        </div>
      </div>

      <!-- Mobile navigation menu -->
      <div
        v-if="isAuthenticated && isMenuOpen"
        class="sm:hidden py-2 space-y-2"
      >
        <router-link
          to="/your-account"
          class="block px-3 py-2 text-off-white hover:text-light-grey text-body-medium"
          @click="isMenuOpen = false"
          >{{ t("nav.profile") }}</router-link
        >
        <router-link
          to="/trainer-profile"
          class="block px-3 py-2 text-off-white hover:text-light-grey text-body-medium"
          @click="isMenuOpen = false"
          >{{ t("nav.trainerProfile") }}</router-link
        >
        <router-link
          to="/my-carnets"
          class="block px-3 py-2 text-off-white hover:text-light-grey text-body-medium"
          @click="isMenuOpen = false"
          >{{ t("nav.passes") }}</router-link
        >
        <router-link
          to="/my-reservations"
          class="block px-3 py-2 text-off-white hover:text-light-grey text-body-medium"
          @click="isMenuOpen = false"
          >{{ t("nav.reservations") }}</router-link
        >
        <router-link
          to="/statistics"
          class="block px-3 py-2 text-off-white hover:text-light-grey text-body-medium"
          @click="isMenuOpen = false"
          >{{ t("nav.statistics") }}</router-link
        >
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useDarkMode } from "@/composables/useDarkMode";
import LanguageSwitcher from "./LanguageSwitcher.vue";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const isMenuOpen = ref(false);
const { isDark, toggleDarkMode } = useDarkMode();

const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
const currentUser = computed(() => store.getters["user/currentUser"]);

const userInitials = computed(() => {
  const { first_name, last_name } = currentUser.value;
  if (first_name && last_name) {
    return `${first_name[0]}${last_name[0]}`.toUpperCase();
  } else if (first_name) {
    return first_name[0].toUpperCase();
  } else if (last_name) {
    return last_name[0].toUpperCase();
  }
  return "";
});

const handleLogout = async () => {
  console.log("handleLogout called");
  await store.dispatch("user/logout");
  console.log("Logout completed, redirecting to login page");
  router.push("/login");
};
</script>
