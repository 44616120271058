import account from "./pl/account";
import app from "./pl/app";
import carnets from "./pl/carnets";
import common from "./pl/common";
import errors from "./pl/errors";
import login from "./pl/login";
import meta from "./pl/meta";
import nav from "./pl/nav";
import reservations from "./pl/reservations";
import trainerProfile from "./pl/trainerProfile";
import users from "./pl/users";
import paymentStatus from "./pl/paymentStatus";
import statistics from "./pl/statistics";

export default {
  account,
  app,
  carnets,
  common,
  errors,
  login,
  meta,
  nav,
  reservations,
  trainerProfile,
  users,
  paymentStatus,
  statistics,
};
