// Importy komponentów
const Carnets = () => import("@/views/reservations/ReservationCarnets.vue"); // Poprawiona ścieżka
const PaymentStatus = () => import("@/views/paymentStatus/PaymentStatus.vue");

export const carnetRoutes = [
  {
    path: "/carnets/:venueId",
    name: "Carnets",
    component: Carnets,
    meta: { requiresAuth: false, title: "Karnety | Playmore — Player" },
  },
  {
    path: "/payment-status/:paymentId",
    name: "PaymentStatus",
    component: PaymentStatus,
    meta: {
      requiresAuth: true,
      title: "Status płatności | Playmore — Player",
    },
  },
];
