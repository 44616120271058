export default {
  header: {
    title: "{name}, you can be proud of yourself!",
    subtitle: "You and your racket make a great team. Here are your stats:",
  },
  general: {
    title: "At a glance",
    total: "You've made",
    totalSuffix: "reservations",
    active: "Active now",
    cancelled: "Cancelled",
    hoursPlayed: "Time spent playing",
    hoursSuffix: "hours",
  },
  venues: {
    title: "Your favorite places",
    empty: "You haven't made any reservations yet",
    reservations: "reservations",
    hoursPlayed: "h spent playing",
    details: {
      address: "Find us at",
      phone: "Call us",
      email: "Email us",
    },
    book: "Book",
  },
  trend: {
    title: "Reservation trend",
    more: "You're booking more often than before",
    less: "You're booking less often than before",
    same: "You maintain a steady activity level",
    recent: "Last 3 months",
    older: "Previous 3 months",
    reservations: "reservations",
  },
  days: {
    title: "Your favorite days",
    empty: "We don't have data about your favorite days yet",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  hours: {
    title: "When you play most",
    empty: "We don't have data about your favorite hours yet",
    timeSuffix: "is your favorite time",
  },
  playStyle: {
    title: "Your Play Style",
    empty: "We don't have enough data about your play style yet",
    averageTime: {
      title: "Average game time",
      description: "You usually play for",
    },
    frequency: {
      title: "Play frequency",
      perWeek: "times per week",
      perMonth: "times per month",
      regular: "You play regularly",
      irregular: "You play irregularly",
    },
    duration: {
      title: "Preferred game duration",
      minutes: "minutes",
      mostCommon: "You most often choose",
    },
    courtType: {
      title: "Preferred court type",
      squash: "Squash court",
      tennis: "Tennis court",
      mostPlayed: "You most often play on",
    },
    gameType: {
      title: "Game type",
      singles: "Singles",
      doubles: "Doubles",
      preferred: "You prefer",
    },
  },
};
