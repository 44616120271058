import api from "@/api";
import { useCache } from "@/composables/useCache";
import { useCookies } from "@/composables/useCookies";

// Inicjalizacja cache'u i cookies
const userCache = useCache("userProfile");
const { setCookie, getCookie, deleteCookie } = useCookies();

// Nazwa ciasteczka dla tokenu
const AUTH_TOKEN_COOKIE = "auth_token";
// Okres ważności ciasteczka w dniach (2 lata)
const COOKIE_EXPIRY_DAYS = 730;

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      is_active: false,
      phone_verified: false,
      email_verified: false,
      phone_number: "",
      marketing_consent: false,
      is_deleted: false,
      user_venue_profiles: [],
      trainer_venue_profiles: [],
      user_sport_skill: [],
    },
    authToken: getCookie(AUTH_TOKEN_COOKIE) || null,
  },
  mutations: {
    setUser(state, user) {
      state.user = {
        ...state.user,
        ...user,
        trainer_venue_profiles: user.trainer_venue_profiles || [],
        user_venue_profiles: user.user_venue_profiles || [],
        user_sport_skill: user.user_sport_skill || [],
      };
      localStorage.setItem("user", JSON.stringify(state.user));

      // Cache user data
      if (user.id) {
        userCache.setInCache(`user-${user.id}`, user);
      }
    },
    setAuthToken(state, token) {
      state.authToken = token;
      if (token) {
        // Ustaw ciasteczko na 2 lata
        setCookie(AUTH_TOKEN_COOKIE, token, COOKIE_EXPIRY_DAYS);
      } else {
        // Usuń ciasteczko jeśli token jest null
        deleteCookie(AUTH_TOKEN_COOKIE);
      }
    },
    clearAuth(state) {
      state.user = {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        is_active: false,
        phone_verified: false,
        email_verified: false,
        phone_number: "",
        marketing_consent: false,
        is_deleted: false,
        user_venue_profiles: [],
        trainer_venue_profiles: [],
        user_sport_skill: [],
      };
      state.authToken = null;
      localStorage.removeItem("user");
      deleteCookie(AUTH_TOKEN_COOKIE);

      // Clear user cache
      userCache.clearCache();
    },
  },
  actions: {
    async initiateOtpLogin(_, phoneNumber) {
      await api.users.initiateOtpLogin({
        source: "web",
        phone_number: phoneNumber,
      });
    },
    async verifyOtpToken(_, { code, phone_number }) {
      return await api.users.verifyOtpToken(code, phone_number);
    },
    setAuthToken({ commit }, token) {
      commit("setAuthToken", token);
    },
    setCurrentUser({ commit }, user) {
      commit("setUser", user);
      if (user.authToken) {
        commit("setAuthToken", user.authToken);
      }
    },
    async fetchUserProfile({ commit, dispatch, state, router }, force = false) {
      try {
        // Try to get from cache first
        if (!force && state.user.id) {
          const cached = userCache.getFromCache(`user-${state.user.id}`);
          if (cached) {
            commit("setUser", cached);
            return cached;
          }
        }

        const response = await api.users.getProfile();
        if (!response || !response.id) {
          throw new Error("User profile response is missing ID");
        }
        commit("setUser", response);
        return response;
      } catch (error) {
        // W przypadku błędu wyloguj użytkownika i przekieruj na stronę logowania
        dispatch("logout");
        if (router) {
          router.push("/login");
        }
        throw error;
      }
    },
    async updateUserProfile({ commit }, userData) {
      const response = await api.users.updateUser(userData);
      commit("setUser", response);

      // Clear cache after update
      if (response.id) {
        userCache.deleteFromCache(`user-${response.id}`);
      }

      return response;
    },
    async refreshUserProfile({ dispatch }) {
      return dispatch("fetchUserProfile", true);
    },
    logout({ commit }) {
      commit("clearAuth");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    currentUser: (state) => state.user,
    userDetails: (state) => ({
      first_name: state.user.first_name || "",
      last_name: state.user.last_name || "",
      email: state.user.email || "",
    }),
    userSportSkills: (state) => state.user.user_sport_skill || [],
  },
};
