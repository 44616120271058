import store from "../../store";

export const authMiddleware = async (to, from, next) => {
  const isAuthenticated = store.getters["user/isAuthenticated"];

  // Jeśli strona wymaga autoryzacji i użytkownik nie jest zalogowany
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/login");
    return;
  }

  // Jeśli użytkownik jest zalogowany i próbuje wejść na stronę logowania
  if (isAuthenticated && to.path === "/login") {
    // Spróbuj pobrać dane użytkownika przed przekierowaniem
    try {
      await store.dispatch("user/fetchUserProfile");
      next("/your-account");
    } catch (error) {
      // Jeśli nie udało się pobrać danych, wyloguj użytkownika
      await store.dispatch("user/logout");
      next("/login");
    }
    return;
  }

  // Jeśli użytkownik jest zalogowany i wchodzi na chronioną stronę
  if (isAuthenticated && to.meta.requiresAuth) {
    try {
      // Odśwież dane użytkownika
      await store.dispatch("user/fetchUserProfile");
      next();
    } catch (error) {
      // Jeśli nie udało się pobrać danych, wyloguj użytkownika
      await store.dispatch("user/logout");
      next("/login");
    }
    return;
  }

  // W pozostałych przypadkach kontynuuj normalnie
  next();
};
