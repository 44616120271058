export default (api) => ({
  async getObjectAvailability(
    venueId,
    venueSportObjectId,
    startTime,
    endTime,
    venueUserProfileId
  ) {
    const response = await api.get(
      `venue/check_availability/${venueSportObjectId}`,
      {
        params: {
          venue_id: venueId,
          venue_sport_object_id: venueSportObjectId,
          start_time: startTime,
          end_time: endTime,
          user_id: venueUserProfileId,
        },
      }
    );
    return response.data;
  },

  async getSingleVenue(venueID) {
    const response = await api.get(`/venue/${venueID}`);
    return response.data;
  },

  async get_object_availabilities(data, venueID) {
    const response = await api.get(
      `/venue/get_object_availabilities/${venueID}`,
      {
        params: data,
      }
    );
    return response.data;
  },

  async getCarnetsFromVenue(venueId) {
    const response = await api.get(`/venue/carnet_type/${venueId}`);
    return response.data;
  },

  async getVenueGroupClasses(venueId, startTime, endTime) {
    const response = await api.get(`/venue/venue_group_classes/${venueId}`, {
      params: {
        start_time: startTime,
        end_time: endTime,
      },
    });
    return response.data;
  },

  async getVenueTrainers(venueId) {
    const response = await api.get(`/venue/trainer_venue_profile/${venueId}`);
    return response.data;
  },

  async getVenues() {
    const response = await api.get("/venue");
    return response.data;
  },
  async getAppSports() {
    const response = await api.get("/app/sport_type");
    return response.data;
  },
});
