export default {
  header: {
    title: "{name}, ви можете пишатися собою!",
    subtitle: "Ви та ваша ракетка - чудова команда. Ось ваша статистика:",
  },
  general: {
    title: "Коротко",
    total: "Ви забронювали",
    totalSuffix: "разів",
    active: "Зараз активних",
    cancelled: "Скасовано",
    hoursPlayed: "Часу проведено на кортах",
    hoursSuffix: "годин",
  },
  venues: {
    title: "Ваші улюблені місця",
    empty: "У вас ще немає бронювань",
    reservations: "бронювань",
    hoursPlayed: "год гри",
    details: {
      address: "Знайдіть нас",
      phone: "Зателефонуйте",
      email: "Напишіть",
    },
    book: "Забронювати",
  },
  trend: {
    title: "Тренд бронювань",
    more: "Ви бронюєте частіше, ніж раніше",
    less: "Ви бронюєте рідше, ніж раніше",
    same: "Ви підтримуєте стабільний рівень активності",
    recent: "Останні 3 місяці",
    older: "Попередні 3 місяці",
    reservations: "бронювань",
  },
  days: {
    title: "Ваші улюблені дні",
    empty: "У нас ще немає даних про ваші улюблені дні",
    monday: "Понеділок",
    tuesday: "Вівторок",
    wednesday: "Середа",
    thursday: "Четвер",
    friday: "П'ятниця",
    saturday: "Субота",
    sunday: "Неділя",
  },
  hours: {
    title: "Коли ви найчастіше граєте",
    empty: "У нас ще немає даних про ваші улюблені години",
    timeSuffix: "ваш улюблений час",
  },
  playStyle: {
    title: "Ваш стиль гри",
    empty: "У нас ще недостатньо даних про ваш стиль гри",
    averageTime: {
      title: "Середній час гри",
      description: "Зазвичай ви граєте по",
    },
    frequency: {
      title: "Частота гри",
      perWeek: "разів на тиждень",
      perMonth: "разів на місяць",
      regular: "Ви граєте регулярно",
      irregular: "Ви граєте нерегулярно",
    },
    duration: {
      title: "Бажана тривалість гри",
      minutes: "хвилин",
      mostCommon: "Найчастіше обираєте",
    },
    courtType: {
      title: "Бажаний тип корту",
      squash: "Корт для сквошу",
      tennis: "Тенісний корт",
      mostPlayed: "Найчастіше граєте на",
    },
    gameType: {
      title: "Тип гри",
      singles: "Одиночні ігри",
      doubles: "Парні ігри",
      preferred: "Ви віддаєте перевагу",
    },
  },
};
