export default {
  title: "Karnety dla obiektu {venue}",
  loading: "Ładowanie karnetów...",
  errors: {
    loading: "Nie udało się załadować karnetów. Spróbuj ponownie później.",
  },
  card: {
    duration: "Czas trwania",
    days: "dni",
    type: {
      label: "Typ",
      amount: "Wartościowy",
      entries: "Wejściowy",
    },
    value: {
      label: "Wartość",
      amount: "zł",
      entries: "wejść",
    },
    price: {
      label: "Cena",
      currency: "zł",
    },
    availableHours: {
      title: "Dostępne godziny",
    },
    actions: {
      buy: "Kup karnet",
    },
  },
};
