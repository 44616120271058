export default {
  title: "Passes for {venue}",
  loading: "Loading passes...",
  errors: {
    loading: "Failed to load passes. Please try again later.",
  },
  card: {
    duration: "Duration",
    days: "days",
    type: {
      label: "Type",
      amount: "Value-based",
      entries: "Entry-based",
    },
    value: {
      label: "Value",
      amount: "PLN",
      entries: "entries",
    },
    price: {
      label: "Price",
      currency: "PLN",
    },
    availableHours: {
      title: "Available hours",
    },
    actions: {
      buy: "Buy pass",
    },
  },
};
