// Importy komponentów
const Login = () => import("@/views/users/Login.vue");
const CompleteRegistration = () =>
  import("@/views/users/CompleteRegistration.vue");
const Account = () => import("@/views/users/Account.vue");
const DumpAccount = () => import("@/views/users/DumpAccount.vue");
const ConfirmEmail = () => import("@/views/users/ConfirmEmail.vue");
const Statistics = () => import("@/views/users/statistics/Statistics.vue");
const UserReservations = () =>
  import("@/views/users/reservations/UserReservations.vue");
const UserCarnets = () => import("@/views/users/carnets/UserCarnets.vue");

export const userRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Logowanie | Playmore — Player" },
  },
  {
    path: "/complete-registration",
    name: "CompleteRegistration",
    component: CompleteRegistration,
    meta: {
      requiresAuth: true,
      title: "Dokończ rejestrację | Playmore — Player",
    },
  },
  {
    path: "/your-account",
    name: "YourAccount",
    component: Account,
    meta: { requiresAuth: true, title: "Twoje konto | Playmore — Player" },
  },
  {
    path: "/dump-account",
    name: "DumpAccount",
    component: DumpAccount,
    meta: { requiresAuth: true, title: "Usuń konto | Playmore — Player" },
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: ConfirmEmail,
    meta: { requiresAuth: false, title: "Potwierdź email | Playmore — Player" },
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    meta: { requiresAuth: true, title: "Statystyki | Playmore — Player" },
  },
  {
    path: "/my-reservations",
    name: "UserReservations",
    component: UserReservations,
    meta: { requiresAuth: true, title: "Moje rezerwacje | Playmore — Player" },
  },
  {
    path: "/my-carnets",
    name: "UserCarnets",
    component: UserCarnets,
    meta: { requiresAuth: true, title: "Moje karnety | Playmore — Player" },
  },
];

export const defaultRoute = {
  path: "/",
  redirect: "/your-account",
  meta: { requiresAuth: true },
};
