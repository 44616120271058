export default {
  title: "Payment Status",
  messages: {
    PENDING: {
      title: "Waiting for your payment",
      description: "Complete the payment process to confirm your reservation",
    },
    PAID: {
      title: "Thank you for your payment!",
      description: "Your reservation has been confirmed",
    },
    VOIDED: {
      title: "Payment was rejected",
      description:
        "Unfortunately, the payment could not be processed. Please try again",
    },
    PARTIALLY_REFUNDED: {
      title: "Payment partially refunded",
      description: "Part of the amount has been returned to your account",
    },
    REFUNDED: {
      title: "Payment refunded",
      description: "The full amount has been returned to your account",
    },
    ABANDONED: {
      title: "Payment not completed",
      description: "The payment time limit has been exceeded",
    },
  },
  refreshButton: "Refresh status",
  errors: {
    fetch: "Failed to fetch payment status",
    invalidId: "Invalid payment identifier",
    notFound: "Payment with provided identifier not found",
    unauthorized: "Unauthorized to view this payment",
    serverError: "Server error occurred while fetching payment status",
  },
  noPaymentId: "Missing payment ID",
};
