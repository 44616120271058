import account from "./ua/account";
import app from "./ua/app";
import carnets from "./ua/carnets";
import common from "./ua/common";
import errors from "./ua/errors";
import login from "./ua/login";
import meta from "./ua/meta";
import nav from "./ua/nav";
import reservations from "./ua/reservations";
import trainerProfile from "./ua/trainerProfile";
import users from "./ua/users";
import paymentStatus from "./ua/paymentStatus";
import statistics from "./ua/statistics";

export default {
  account,
  app,
  carnets,
  common,
  errors,
  login,
  meta,
  nav,
  reservations,
  trainerProfile,
  users,
  paymentStatus,
  statistics,
};
